

export default {
    data(){
        return{
            aftersale:[{
                Type:'退货退款',
                Reason:'7天无理由',
                Status:'1',

            }],
            logistic:[{
                Name:'顺丰速运',
                LogisticNo:'SF11111',
                AddDTime:'2021-10-13 14：23：56',
                LogisticStatus:'运输中',
            }]
        }
    },
    
    methods:{
        Back(){
            this.$router.go(-1);
        }
    }
}
